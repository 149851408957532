<template>
  <div>
    <v-scroll-x-transition :hide-on-leave="true">
      <component
        :is="component"
        ref="form"
      />
    </v-scroll-x-transition>
    <CardNav
      :nextText="nextText"
      :prevText="$t('registration.navButtons.back')"
      :nextDisabled="!isFormValid"
      :nextLoading="nextLoading"
      @previous="previous"
      @next="next"
      class="mt-8"
      onboarding
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'SignUp',
  components: {
    CardNav: lazyLoad('components/Shared/CardNav'),
    Info: lazyLoad('views/SignUp/Info'),
    Create: lazyLoad('views/SignUp/Create'),
    Confirm: lazyLoad('views/SignUp/Confirm'),
    Finish: lazyLoad('views/SignUp/Finish'),
  },
  data() {
    return {
      nextLoading: false,
    };
  },
  computed: {
    ...mapGetters('registration', [
      'steps',
      'index',
      'isFormValid',
    ]),
    component() {
      return this.steps[this.index];
    },
    nextText() {
      if (this.component === 'Confirm') {
        return this.$t('registration.navButtons.confirm');
      }
      if (this.component === 'Finish') {
        return this.$t('registration.navButtons.finish');
      }
      return this.$t('registration.navButtons.next');
    },
  },
  async created() {
    await this.$store.dispatch('registration/setDownloadUrl');
    if (this.$route.query.email) {
      this.setEmail(this.$route.query.email);
      this.setStep(2);
    }
  },
  methods: {
    ...mapActions('registration', [
      'nextStep',
      'previousStep',
      'setStep',
      'setEmail',
    ]),
    previous() {
      if (this.index === 0) this.$router.go(-1);
      this.previousStep();
    },
    async next() {
      this.nextLoading = true;
      if (this.component === 'Info') {
        this.nextStep();
      } else {
        await this.submitForm();
      }
      this.nextLoading = false;
    },
    async submitForm() {
      await this.$refs.form.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.right-side {
  margin-left: 50%;
}
</style>
